<template>
  <div class="ps-tab active" id="sign-in">
    <div class="ps-form__content">
      <h5>Log In Your Account</h5>
      <div v-if="errors.message" class="alert alert-danger" role="alert">
        {{ errors.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          v-model="user.email"
          type="email"
          placeholder="Email address"
        />
        <small v-if="errors.email" class="mobile-number-error text-danger">{{
          errors.email[0]
        }}</small>
      </div>
      <div class="form-group form-forgot">
        <input
          class="form-control"
          v-model="user.password"
          type="password"
          placeholder="Password"
        />
        <router-link :to="{ name: 'forgot_password' }" hidden
          >Forgot?</router-link
        >
        <small v-if="errors.password" class="mobile-number-error text-danger">{{
          errors.password[0]
        }}</small>
      </div>
      <div class="form-group">
        <div class="ps-checkbox">
          <input
            class="form-control"
            type="checkbox"
            id="remember-me"
            name="remember-me"
          />
          <label for="remember-me">Remember me</label>
        </div>
      </div>
      <div class="form-group submit">
        <button
          class="ps-btn ps-btn--fullwidth login"
          :disabled="isLoading"
          @click.prevent="onSubmit()"
        >
          {{ isLoading ? "Processing..." : "Login" }}
          <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
          <div
            v-if="isLoading"
            class="spinner-border text-light"
            role="status"
          ></div>
        </button>
      </div>
      <div>
        <router-link :to="{ name: 'forgot_password' }"
          >Forgot Password?</router-link
        >
      </div>
    </div>
    <AuthFooter :section="'login'"></AuthFooter>
  </div>
</template>

<script>
import axios from "axios";
import AuthFooter from "./AuthFooter";

export default {
  name: "Login",
  components: { AuthFooter },
  data() {
    return {
      user: {
        email: "",
        verification_code: "",
        password: "",
        confirm_password: "",
      },
      errors: {
        email: [],
        password: [],
        message: "",
      },
      isLoading: false,
      verify: false,
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      let data = { email: this.user.email, password: this.user.password };
      axios
        .post("login", data)
        .then((response) => {
          if (response.data.success) {
            this.$toasted.success("You have successfully been logged in");
            this.$store.dispatch("auth/updateProfile", response.data.user);
            this.$store.dispatch("auth/updateToken", response.data.token);
            let nextPage = "home";
            if (response.data.user.is_admin) {
              nextPage = "admin_dashboard";
            } else if (response.data.user.shop) {
              nextPage = "merchant_dashboard";
            }
            if (this.$store.state.auth.next) {
              nextPage = this.$store.state.auth.next;
              this.$store.commit("auth/next", null);
            }
            this.$router.push({ name: nextPage });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.$toasted.error("Login failed, please try again.");
          if (error) {
            if (error.response && error.response.status === 422) {
              this.message = "Login failed. Please enter all required fields.";
              this.errors = error.response.data.errors;
            } else if (error.response && error.response.status === 401) {
              this.errors.message = error.response.data.message;
            } else {
              this.errors.message = error.backendErrors[0];
            }
          } else {
            this.errors.message = "Unexpected error occurred.";
          }
          this.isError = true;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
